import axios from "axios";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ResetUserPassword = () => {
    const [notification, setNotification] = useState({ show: false, title: "", body: "" });
    const urlParams = new URLSearchParams(window.location.search);
    const [formData, setFormData] = useState({ newPassword: "", code: urlParams.get("hash"), role: urlParams.get("role") })
    const [password, setPassword] = useState({ main: "", repeat: "" });
    const [error, setError] = useState(false);

    const submitForm = (e) => {
        e.preventDefault()
        if (password.main === password.repeat) {
            axios.put('/api/password/reset', formData)
                .then(() => {
                    setNotification({ show: true, title: "Success", body: "Password changed" })
                })
                .catch(() => {
                    setNotification({ show: true, title: "Failed", body: "Something went wrong" })
                })
        }
    }
    return (
        <div className="reset-user-password-container">
            <div className="form-container">
                <img src="/static/icons/logo.png" style={{ width: "330px", marginBottom: "20px" }} />
                <h3 style={{ marginBottom: "20px" }}>Reset Password</h3>
                <form onSubmit={submitForm} className="reset-password-form">
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" className="form-control" placeholder="Enter new password" onChange={(e) => {
                            setFormData((prev) => ({ ...prev, newPassword: e.target.value }))
                            setPassword((prev) => ({ ...prev, main: e.target.value }))
                            setError(false);
                        }} onBlur={() => {
                            if (password.main !== password.repeat && password.repeat !== "") {
                                setError(true)
                            }
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input type="password" className="form-control" placeholder="Repeat password" onChange={(e) => {
                            setPassword((prev) => ({ ...prev, repeat: e.target.value }))
                            setError(false);
                        }} onBlur={() => {
                            if (password.main !== password.repeat) {
                                setError(true)
                            }
                        }} />
                        {error && <span style={{ color: "red", fontSize: "14px" }}>Passwords don't match</span>}
                    </div>
                    <Button variant="dark" type="submit" style={{ width: "100%" }}>Reset</Button>
                </form>
            </div>

            <Modal show={notification.show} onHide={() => {
                if (notification.title === "Success" && formData.role === "users") {
                    window.close()
                }else if(formData.role === "admin"){
                    window.location.href = "/"
                }
                setNotification((prev) => ({ ...prev, show: false }))
            }}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer><Button variant="dark" onClick={() => {
                    if (notification.title === "Success" && formData.role === "users") {
                        window.close()
                    }else if(formData.role === "admin"){
                        window.location.href = "/"
                    }
                    setNotification((prev) => ({ ...prev, show: false }))
                }}>Ok</Button></Modal.Footer>
            </Modal>
        </div>
    )
}

export default ResetUserPassword