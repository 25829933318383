import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';

const AddAccountsModal = ({ show, setShow, setFormData, formData, onSubmit, action, role, username }) => {
    const [notification, setNotification] = useState({ show: false, title: "", body: "", button: "" });
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        if (role === "sadmin") {
            axios('/api/admins?limit=100&page=1')
                .then((response) => {
                    const data = response.data.data;
                    setAdmins([...data]);
                })
                .catch((err) => {
                    setNotification({ show: true, title: "Error", body: "Admins not loaded", button: "Close" })
                })
        }else{
            setFormData((prev) => ({ ...prev, admin: username }))
        }
    }, []);

    return (
        <>
            <Modal show={show} onHide={setShow} size={"lg"} centered>
                <Modal.Header><h4>Add new account</h4></Modal.Header>
                <Modal.Body>
                    <form className={"add-new-users-form"} onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Auction</label>
                                    <select name={"account"} required value={formData.auction}
                                            className={"form-control"} onChange={(e) => setFormData((prev) => ({
                                        ...prev,
                                        auction: e.target.value
                                    }))}>
                                        <option>Select auction</option>
                                        <option value={"copart"}>Copart</option>
                                        <option value={"iaai"}>IAAI</option>
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>Buyer/Email address</label>
                                    <input type={"text"} required value={formData.buyer} className={"form-control"}
                                           placeholder={"Enter buyer or email address"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, buyer: e.target.value}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Password</label>
                                    <input type={"password"} required value={formData.password}
                                           className={"form-control"}
                                           placeholder={"Enter password"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, password: e.target.value}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Fee</label>
                                    <input type={"number"} required value={formData.fee}
                                           className={"form-control"}
                                           placeholder={"Enter Fee"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, fee: e.target.value}))
                                    }}/>
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                {role === "sadmin" && <div className={"form-group"}>
                                    <label>Admin</label>
                                    <select value={formData.admin} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, admin: e.target.value}));
                                    }}>
                                        <option>Select Admin</option>
                                        {
                                            admins.map((c) => (
                                                <option value={c.username} key={c.id}>{c.username}</option>))
                                        }
                                    </select>
                                </div>}
                                <div className={"form-group"}>
                                    <label>Comment</label>
                                    <textarea required style={{ height: "125px" }} value={formData.comment}
                                        className={"form-control"}
                                        placeholder={"Add comment"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, comment: e.target.value }))
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-12"} style={{ marginTop: "25px" }}>
                            <button className={"blue-button"} style={{ width: "100%" }} type={"submit"}>{action === "edit" ? 'Save' : 'Create Account'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddAccountsModal