import DataTable from "../../Common/DataTable/DataTable";
import {useEffect, useState} from "react";
import axios from "axios";
import AddNewUsers from "./AddNewUsers";
import './Users.css'
import {Modal, Button} from 'react-bootstrap'
import ViewUsersData from "./ViewUsersData";
const Users = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "", surname: "", username: "", country: "", city: "", email: "", phone: "", password: "", adminName: "",
        copartStatus: false, iaaiStatus: false, copartAccount: "", iaaiAccount: [], hasLimit: false, bidLimit: 0, copartAdditionalAccount: []
    });
    const [notification, setNotification] = useState({show: false, title: "", body: "", button: ""});
    const [passwordError, setPasswordError] = useState(false);
    const [showViewUsersModal, setShowViewUsersModal] = useState(false);
    const [active, setActive] = useState({});
    const [action, setAction] = useState("");
    const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false);

    const headers = ["Name", "Surname", "Username", "email", "Phone", "Admin", ""];
    const showParams = ["name", "surname", "username", "email", "phone", "adminName"];

    const actions = [
        {
            name: "Add new",
            action: () => {
                setFormData({
                    name: "", surname: "", username: "", country: "", city: "", email: "", phone: "", password: "", adminName: "",
                    copartStatus: false, iaaiStatus: false, copartAccount: "", iaaiAccount: [], hasLimit: false, bidLimit: 0
                });
                setShowAddUsersModal(true);
                setAction("add");
            }
        }
    ];

    const rowActions = [
        {
            name: "View",
            id: 1002,
            action: () => {
                setShowViewUsersModal(true)
            }
        },
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                setShowAddUsersModal(true);
                setAction("edit");
                setFormData({
                    name: act.name, surname: act.surname, username: act.username, country: act.country, city: act.city, email: act.email,
                    phone: act.phone, adminName: act.adminName,
                    copartStatus: act.copartStatus, iaaiStatus: act.iaaiStatus, copartAccount: act.copartAccount, iaaiAccount: typeof act.iaaiAccount == "string" ? JSON.parse(act.iaaiAccount) : act.iaaiAccount,
                    hasLimit: act.hasLimit, bidLimit: act.bidLimit, copartAdditionalAccount: typeof act.copartAdditionalAccounts == "string" ? JSON.parse(act.copartAdditionalAccounts) : act.copartAdditionalAccounts
                });
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: () => {
                setShowDeleteUsersModal(true)
            }
        }
    ]

    const handlers = {
        search(keyword){
            setKeyword(keyword);
        },
        async addNewUser(){
            if(formData.password.length < 7){
                setPasswordError(true)
            }else{
                try{
                    const response = await axios.post('/api/users', formData);
                    setNotification({show: true, title: "Success", body: "User created successfully", button: "Ok"});
                    setShowAddUsersModal(false);
                    const data = [{
                        id: response.data.id, name: formData.name, surname: formData.surname, username: formData.username, country: formData.country,
                        city: formData.city,  email: formData.email, phone: formData.phone, adminName: formData.adminName,
                        copartStatus: formData.copartStatus, iaaiStatus: formData.iaaiStatus, copartAccount: formData.copartAccount, iaaiAccount: formData.iaaiAccount
                    }, ...users];
                    setUsers([...data]);
                }catch (err){
                    setNotification({show: true, title: "An error has been detected", body: err.response.data.message, button: "Ok"})
                }
            }
        },
        async editUser(){
            try{
                const response = await axios.put(`/api/users/${active.id}`, formData);
                const data = users.map((u) => {
                    if(u.id === active.id){
                        return {id: u.id, ...formData}
                    }else{
                        return {...u}
                    }
                })
                setUsers([...data]);
                setNotification({show: true, title: "Success", body: "User updated successfully", button: "Ok"});
                setShowAddUsersModal(false)
            }catch (err){
                setNotification({show: true, title: "An error has been detected", body: err.response.data.message, button: "Ok"})
            }
        },
        async deleteUser(){
            try {
                const response = await axios.delete(`/api/user/${active.id}`);
                const data = users.filter((u) => u.id !== active.id);
                setUsers([...data]);
                setShowDeleteUsersModal(false);
                setNotification({show: true, title: "Success", body: "User removed successfully", button: "Ok"});
            }catch (err){
                setShowDeleteUsersModal(false);
                setNotification({show: true, title: "An error has been detected", body: "Something went wrong", button: "Ok"})
            }
        }
    };

    useEffect(() => {
        axios(`/api/users?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setUsers([...data]);
                setTotal(response.data.total)
            })
            .catch((err) => {

            })
    }, [limit, page, keyword])

    return(
        <>
            <DataTable
                withoutSearchButton={true}
                headers={headers}
                searchHandler={handlers.search}
                title={"Users"}
                actionButtons={actions}
                limit={limit}
                setLimit={setLimit}
                limitControl={true}
                data={users}
                showParams={showParams}
                pageChanged={page}
                setPageChanged={setPage}
                total={total}
                rowActions={true}
                rowActionsList={rowActions}
                setActiveRow={setActive}
            />
            {showAddUsersModal &&
                <AddNewUsers show={showAddUsersModal} setShow={setShowAddUsersModal} action={action} formData={formData}
                             setFormData={setFormData} onSubmit={action === 'add' ? handlers.addNewUser : handlers.editUser} passwordError={passwordError}/>}
            {showViewUsersModal && <ViewUsersData data={active} show={showViewUsersModal} hide={setShowViewUsersModal}/>}
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteUsersModal} onHide={() => setShowDeleteUsersModal(false)}>
                <Modal.Header><h4>Delete user</h4></Modal.Header>
                <Modal.Body>Do you want to process?</Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={handlers.deleteUser}>Yes</Button>
                    <button className={"blue-button"} onClick={() => setShowDeleteUsersModal(false)}>No</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Users