import {Modal} from 'react-bootstrap'

const ViewUsersData = ({show, data, hide}) => {
    console.log(data)
    return (
        <Modal show={show} onHide={() => hide(false)} size={"lg"} centered>
            <Modal.Header><h4>{`${data.name} ${data.surname}`}</h4></Modal.Header>
            <Modal.Body>
                <div className={"view-users-modal row"}>
                    <div className={"col-md-6"}>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Username</strong>
                            <p className={"view-users-item-value"}>{data.username}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Email address</strong>
                            <p className={"view-users-item-value"}>{data.email}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Phone</strong>
                            <p className={"view-users-item-value"}>{data.phone}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Admin</strong>
                            <p className={"view-users-item-value"}>{data.adminName}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Country</strong>
                            <p className={"view-users-item-value"}>{data.country}</p>
                        </div>
                    </div>
                    <div className={"col-md-6"}>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>City</strong>
                            <p className={"view-users-item-value"}>{data.city}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Copart status</strong>
                            <p className={"view-users-item-value"}>{data.copartStatus ? "Active" : "Inactive"}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>IAAI status</strong>
                            <p className={"view-users-item-value"}>{data.iaaiStatus ? "Active" : "Inactive"}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>Copart account</strong>
                            <p className={"view-users-item-value"}>{data.copartAccount !== "" ? data.copartAccount : "No account"}</p>
                        </div>
                        <div className={"view-users-item"}>
                            <strong className={"view-users-item-title"}>IAAI accounts</strong>
                            <p className={"view-users-item-value"}>{data.iaaiAccount.length > 0 ? (
                                typeof data.iaaiAccount == "string" ? JSON.parse(data.iaaiAccount).map((a) => `${a.label}, `) : data.iaaiAccount.map((a) => `${a.label}, `)
                            ) : "No account"}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={"blue-button"} onClick={() => hide(false)}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewUsersData