import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import Select from 'react-select';

const AddAdminModal = ({show, setShow, setFormData, formData, passwordError, onSubmit, action}) => {
    const [notification, setNotification] = useState({show: false, title: "", body: "", button: ""});
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://countriesnow.space/api/v0.1/countries/positions'
        };

        axios(config)
            .then((response) => {
                const data = response.data.data;
                setCountries([...data])
            })
            .catch((err) => {
                setNotification({show: true, title: "Error", body: "Countries not loaded", button: "Close"})
            })
    }, [])

    return (
        <>
            <Modal show={show} onHide={setShow} size={"lg"} centered>
                <Modal.Header><h4>Add new admin</h4></Modal.Header>
                <Modal.Body>
                    <form className={"add-new-users-form"} onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit()
                    }}>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Name</label>
                                    <input required type={"text"} value={formData.name} className={"form-control"}
                                           placeholder={"Enter name"} onChange={(e) => {
                                           setFormData((prev) => ({...prev, name: e.target.value}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Surname</label>
                                    <input required type={"text"} value={formData.surname} className={"form-control"}
                                           placeholder={"Enter surname"} onChange={(e) => {
                                           setFormData((prev) => ({...prev, surname: e.target.value}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Country</label>
                                    <select required value={formData.country} className={"form-control"}
                                            onChange={(e) => {
                                                setFormData((prev) => ({...prev, country: e.target.value}));
                                                const urlencoded = new URLSearchParams();
                                                urlencoded.append("country", e.target.value);
                                                let config = {
                                                    method: 'post',
                                                    maxBodyLength: Infinity,
                                                    url: 'https://countriesnow.space/api/v0.1/countries/cities',
                                                    headers: {
                                                        'Content-Type': 'application/x-www-form-urlencoded'
                                                    },
                                                    data: urlencoded
                                                };

                                                const phones = {
                                                    method: 'post',
                                                    maxBodyLength: Infinity,
                                                    url: 'https://countriesnow.space/api/v0.1/countries/codes',
                                                    headers: {
                                                        'Content-Type': 'application/x-www-form-urlencoded'
                                                    },
                                                    data: urlencoded
                                                };

                                                axios(phones)
                                                    .then((response) => {
                                                        const data = response.data.data;
                                                        setFormData((prev) => ({...prev, phone: `${data.dial_code} `}));
                                                    })
                                                    .catch((err) => {
                                                        setNotification({
                                                            show: true,
                                                            title: "Error",
                                                            body: "Dial code not received",
                                                            button: "Close"
                                                        })
                                                    })

                                                axios(config)
                                                    .then((response) => {
                                                        const data = response.data.data;
                                                        setCities([...data]);
                                                    })
                                                    .catch((err) => {
                                                        setNotification({
                                                            show: true,
                                                            title: "Error",
                                                            body: "Cities not loaded",
                                                            button: "Close"
                                                        })
                                                    })
                                            }}>
                                        <option>Select country</option>
                                        {
                                            countries.map((c) => (
                                                <option value={c.name} key={c.iso2}>{c.name}</option>))
                                        }
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>City</label>
                                    <select required value={formData.city} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, city: e.target.value}));
                                    }}>
                                        <option>Select City</option>
                                        {
                                            cities.map((c) => (<option value={c} key={c}>{c}</option>))
                                        }
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>Username</label>
                                    <input type={"text"} required value={formData.username} className={"form-control"}
                                           placeholder={"Enter username"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, username: e.target.value}))
                                    }}/>
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Password</label>
                                    <input type={"password"} required={action !== "edit"} value={formData.password}
                                           className={"form-control"}
                                           placeholder={"Enter password"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, password: e.target.value}))
                                    }}/>
                                    {passwordError && <span className={"authorization-error"}>Please insert more then 6 symbol</span>}
                                </div>
                                <div className={"form-group"}>
                                    <label>Email</label>
                                    <input type={"email"} required value={formData.email} className={"form-control"}
                                           placeholder={"Enter email"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, email: e.target.value}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Phone</label>
                                    <input type={"text"} required value={`${formData.phone}`} className={"form-control"}
                                           placeholder={"Enter phone"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, phone: `${e.target.value}`}))
                                    }}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Role</label>
                                    <select required value={`${formData.role}`} className={"form-control"}
                                            onChange={(e) => {
                                            setFormData((prev) => ({...prev, role: `${e.target.value}`}))
                                            }}>
                                        <option>Select role</option>
                                        <option value={"admin"}>Admin</option>
                                        <option value={"sadmin"}>Super admin</option>
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>Controls</label>
                                    <div className={'form-checkboxes'}>
                                        <div className={'form-check'}>
                                            <input id={"copart-status"} type={"checkbox"} onChange={(e) => {
                                                setFormData((prev) => ({...prev, status: e.target.checked}))
                                            }} className={"form-check-input"} defaultChecked={[formData].some(i => i.status === true)}/>
                                            <label htmlFor={"copart-status"}
                                                   className={"form-check-label"}>Status</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-12"} style={{marginTop: "25px"}}>
                            <button className={"blue-button"} style={{width: "100%"}} type={"submit"}>{action === "edit" ? 'Save' : 'Create Admin'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddAdminModal