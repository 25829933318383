import { createSlice } from "@reduxjs/toolkit";

const initialUsers = {
    isLoggedIn: null,
    username: "",
    name: "",
    surname: "",
    email: "",
    role: "",
    initials: "",
    userId: ""
}

const user = createSlice({
    name: "users",
    initialState: initialUsers,
    reducers: {
        changeIsLoggedIn(state, action){
            state.isLoggedIn = action.payload
        },
        saveUserData(state, action){
            state.role = action.payload.role;
            state.username = action.payload.username;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.email = action.payload.email;
            state.initials = action.payload.initials;
            state.userId = action.payload.userId;
        },
    }
})

export const usersActions = user.actions;

export default user.reducer