import './Auth.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { usersActions } from "../../store/user";

const Auth = ({ authorized }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [error, setError] = useState({ username: false, password: false });
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [username, setUsername] = useState("");
    const [notification, setNotification] = useState({ show: false, title: "", body: "" });

    const authorization = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/admin/auth', formData);
            const data = response.data.data;
            dispatch(usersActions.saveUserData(data));
            dispatch(usersActions.changeIsLoggedIn(true));
            window.location.href = "/";
        } catch (err) {
            const message = err.response.data.message;
            if (message === "Wrong password") {
                setError({ username: false, password: true })
            }
            if (message === "Wrong username") {
                setError({ username: true, password: false })
            }
            if(message === "Access restricted"){
                setNotification({ show: false, title: message, body: err.response.data.description })
            }
        }
    }

    const resetPassword = () => {
        axios.post('/api/admins/reset-password', { email: username })
            .then(() => {
                setNotification({show: true, title: "Success", body: "Email sent"});
                setShowResetPasswordModal(false);
            })
            .catch((err) => {
                const message = err.response.data;
                setNotification({show: true, title: "Failed", body: message})
            })
    }

    return (
        <>
            {authorized === false && <div className="auth-container">
                <div className="auth-content-box">
                    <img src="/static/icons/logo.png" alt='logo' className='auth-logo' />
                    <h2 className="auth-title">Log In</h2>
                    <form className="authorization-form" onSubmit={authorization}>
                        <div className='form-group '>
                            <label>Username:</label>
                            <input type='text' placeholder="Enter username" required className='form-control'
                                onChange={(e) => {
                                    setFormData((prev) => ({ ...prev, username: e.target.value }));
                                    setError({ username: false, password: false });
                                }} />
                            {error.username && <span className={"authorization-error"}>Wrong username</span>}
                        </div>
                        <div className='form-group'>
                            <label>Password:</label>
                            <input type='password' placeholder="Enter password" className='form-control'
                                onChange={(e) => {
                                    setFormData((prev) => ({ ...prev, password: e.target.value }));
                                    setError({ username: false, password: false });
                                }} />
                            {error.password && <span className={"authorization-error"}>Wrong password</span>}
                        </div>
                        <div className='flex-space-between'>
                        </div>
                        <Button style={{ marginBottom: "20px" }} type="submit" variant="dark button-width">Log In</Button>
                        <div className={"auth-action-buttons"}>
                            <span className={"reset-password"} onClick={() => setShowResetPasswordModal(true)}>Reset password</span>
                        </div>
                    </form>
                    <Modal show={showResetPasswordModal} onHide={() => setShowResetPasswordModal(false)} centered>
                        <Modal.Header><h3>Reset Password</h3></Modal.Header>
                        <Modal.Body>
                            <div className='form-group'>
                                <label>Enter username</label>
                                <input type='text' className='form-control' onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <Button style={{ width: "100%" }} variant='dark' onClick={resetPassword}>Send Reset Password Link</Button>
                        </Modal.Body>
                    </Modal>
                    <Modal show={notification.show} onHide={() => {
                        if (notification.title === "Success") {
                            window.close()
                        }
                        setNotification((prev) => ({ ...prev, show: false }))
                    }}>
                        <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                        <Modal.Body>{notification.body}</Modal.Body>
                        <Modal.Footer><Button variant="dark" onClick={() => {
                            if (notification.title === "Success") {
                                window.close()
                            }
                            setNotification((prev) => ({ ...prev, show: false }))
                        }}>Ok</Button></Modal.Footer>
                    </Modal>
                </div>
            </div>}
        </>
    )
}

export default Auth