import {useState, useEffect} from "react";
import DataTable from "../../Common/DataTable/DataTable";
import {Modal, Button} from 'react-bootstrap';
import axios from "axios";
import AddAccountsModal from "./AddAccountsModal";

const Accounts = ({role, username}) => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [showAddAccountsModal, setShowAddAccountsModal] = useState(false);
    const [formData, setFormData] = useState({
        password: "", buyer: "", auction: "", comment: "", admin: "", fee: ""
    });
    const [notification, setNotification] = useState({show: false, title: "", body: "", button: ""});
    const [active, setActive] = useState({});
    const [action, setAction] = useState("");
    const [showDeleteAccountsModal, setShowDeleteAccountsModal] = useState(false);

    const headers = ["Auction", "Buyer", "admin", "Comment", "Fee", ""];
    const showParams = ["auction", "buyer", "admin", "comment", "fee"];

    const actions = [
        {
            name: "Add new",
            action: () => {
                setFormData({
                    password: "", buyer: "", auction: "", comment: "", admin: "", fee: ""
                });
                setShowAddAccountsModal(true);
                setAction("add");
            }
        }
    ];

    const rowActions = [
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                setShowAddAccountsModal(true);
                setAction("edit");
                setFormData({
                    password: act.password,
                    buyer: act.buyer,
                    auction: act.auction,
                    comment: act.comment,
                    admin: act.admin,
                    fee: act.fee
                });
            }
        },
        {
            name: "Main account setter",
            id: 1001,
            action: (act) => {
                axios.put(`/api/accounts/${act.id}/main-account`)
                    .then(() => {
                        axios(`/api/accounts?page=${page}&limit=${limit}&keyword=${keyword}`)
                            .then((response) => {
                                const data = response.data.data;
                                setAccounts([...data]);
                                setTotal(response.data.total)
                            })
                            .catch((err) => {
                
                            })
                    })
                    .catch((err) => {
                        setNotification({
                            show: true,
                            title: "An error has been detected",
                            body: "Something went wrong",
                            button: "Ok"
                        })
                    })
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: () => {
                setShowDeleteAccountsModal(true)
            }
        }
    ]

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        },
        async addNewAccount() {
            try {
                const response = await axios.post('/api/accounts', formData);
                setNotification({show: true, title: "Success", body: "Account added successfully", button: "Ok"});
                setShowAddAccountsModal(false);
                const data = [{
                    id: response.data.id,
                    buyer: formData.buyer,
                    auction: formData.auction,
                    admin: formData.admin,
                    comment: formData.comment,
                }, ...accounts];
                setAccounts([...data]);
            } catch (err) {
                setNotification({
                    show: true,
                    title: "An error has been detected",
                    body: "Something went wrong",
                    button: "Ok"
                })
            }
        },
        async editAccount() {
            try {
                const response = await axios.put(`/api/accounts/${active.id}`, formData);
                const data = accounts.map((a) => {
                    if (a.id === active.id) {
                        return {id: a.id, ...formData}
                    } else {
                        return {...a}
                    }
                })
                setAccounts([...data]);
                setNotification({show: true, title: "Success", body: "Account updated successfully", button: "Ok"});
                setShowAddAccountsModal(false)
            } catch (err) {
                setNotification({
                    show: true,
                    title: "An error has been detected",
                    body: "Something went wrong",
                    button: "Ok"
                })
            }
        },
        async deleteAccount() {
            try {
                const response = await axios.delete(`/api/accounts/${active.id}`);
                const data = accounts.filter((a) => a.id !== active.id);
                setAccounts([...data]);
                setShowDeleteAccountsModal(false);
                setNotification({show: true, title: "Success", body: "Account removed successfully", button: "Ok"});
            } catch (err) {
                setShowDeleteAccountsModal(false);
                setNotification({
                    show: true,
                    title: "An error has been detected",
                    body: "Something went wrong",
                    button: "Ok"
                })
            }
        }
    }

    useEffect(() => {
        axios(`/api/accounts?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setAccounts([...data]);
                setTotal(response.data.total)
            })
            .catch((err) => {

            })
    }, [limit, page, keyword])

    return (
        <>
            <DataTable
                withoutSearchButton={true}
                headers={headers}
                searchHandler={handlers.search}
                title={"Accounts"}
                actionButtons={actions}
                limit={limit}
                setLimit={setLimit}
                limitControl={true}
                data={accounts}
                showParams={showParams}
                pageChanged={page}
                setPageChanged={setPage}
                total={total}
                rowActions={true}
                rowActionsList={rowActions}
                setActiveRow={setActive}
            />

            {showAddAccountsModal &&
                <AddAccountsModal role={role} username={username} show={showAddAccountsModal} setShow={setShowAddAccountsModal} formData={formData}
                                  setFormData={setFormData} onSubmit={action === "add" ? handlers.addNewAccount : handlers.editAccount} action={action}/>}
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteAccountsModal} onHide={() => setShowDeleteAccountsModal(false)}>
                <Modal.Header><h4>Delete account</h4></Modal.Header>
                <Modal.Body>Do you want to process?</Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={handlers.deleteAccount}>Yes</Button>
                    <button className={"blue-button"} onClick={() => setShowDeleteAccountsModal(false)}>No</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Accounts