/* eslint-disable */
import './App.css';
import Auth from './Common/Auth/Auth';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import axios from "axios";
import {usersActions} from "./store/user";
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "./Common/Header/Header";
import Users from "./Components/Users/Users";
import Accounts from "./Components/Accounts/Accounts";
import Admins from "./Components/Admins/Admins";
import Logs from "./Components/Logs/Logs";
import Dashboard from "./Components/Dashboard/Dashboard";
import ResetUserPassword from './Components/ResetPassword/ResetUserPassword';
import Privacy from './Components/Privacy';
import Actions from './Components/Actions/Actions';

function App() {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const admin = useSelector((state) => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get('/api/admin')
            .then((response) => {
                const data = response.data.data;
                dispatch(usersActions.saveUserData(data));
                dispatch(usersActions.changeIsLoggedIn(true));
            })
            .catch((err) => {
                dispatch(usersActions.changeIsLoggedIn(false));
            })
    }, [])

    return (
        <div className="App">
            {isLoggedIn && <Header role={admin.role}/>}
            {
                isLoggedIn ? (
                    <Switch>
                        <Route path={"/"} exact><Dashboard role={admin.role}/></Route>
                        <Route path={"/users"}><Users/></Route>
                        <Route path={"/accounts"}><Accounts role={admin.role} username={admin.username}/></Route>
                        {admin.role === "sadmin" && <Route path={"/admins"}><Admins/></Route>}
                        <Route path={"/users-logs"}><Logs/></Route>
                        <Route path={"/actions"}><Actions/></Route>
                    </Switch>
                ) : (
                    <Switch>
                        <Route path="/privacy-and-policy"><Privacy/></Route>
                        <Route path='/reset-password' exact>
                            <ResetUserPassword />
                        </Route>
                        <Auth authorized={isLoggedIn}/>
                    </Switch>
                )
            }
        </div>
    );
}

export default App;
